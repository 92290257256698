<template>
  <div v-if="reports.length">
    <section class="px-4 relative top-20 pb-32 md:pb-40 md:pt-20 pt-12" >
      <div class="container">
        <h2 class="font-bold text-agra-green-dark font-montserrat mb-10 lg:mb-12 text-2xl md:text-4xl max-w-[800px] w-full">
          Data Explorer
        </h2>
        <div class="pb-12 lg:pb-20 border-b-2 border-agra-green">
          <div class="relative">
            <div class="absolute w-full h-full -inset-1.5 bg-black bg-opacity-10 blur-md"></div>
            <Report size="large" :report="featuredReport ? featuredReport : reports[0]" actions="true"></Report>
          </div>
        </div>
      </div>
    </section>
    <section class="px-4 pb-20"  ref="grid-reports">
      <div class="container">
        <div class="lg:flex items-center justify-between mb-10">
          <h2 class="text-2xl text-agra-green-dark lg:text-4xl font-bold lg:mb-0 mb-4">Explore our data in more detail</h2>
          <div class="hidden sm:flex space-x-4 items-center flex-wrap">
            <div class="border border-black relative">
              <input v-model="filters.term" type="text" placeholder="Search..." class="block px-4 py-2 focus:outline-none w-28">
              <button class="absolute right-1 top-1 p-1"><i class="fas fa-search"></i></button>
            </div>
            <div class="border border-black relative sm:my-0 my-4">
              <i class="fas fa-angle-down absolute right-3 top-2.5"></i>
              <select v-model="filters.region"
                      class="appearance-none focus:outline-none border-none focus:border-none w-full pl-3 pr-6 py-2 cursor-pointer">
                <option  class="w-full block mb-1" :value="null">All</option>
                <option v-for="region in regions" :key="region" :value="region" class="w-full block mb-1">{{ region }}</option>
              </select>
            </div>
            <div class="border border-black relative">
              <i class="fas fa-angle-down absolute right-3 top-2.5"></i>
              <select v-model="filters.tag"
                      class="appearance-none focus:outline-none border-none focus:border-none w-full pl-3 pr-6 py-2 cursor-pointer">
                <option  class="w-full block mb-1" :value="null">All</option>
                <option v-for="tag in tags" :key="tag" :value="tag" class="w-full block mb-1">{{ tag }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="grid sm:grid-cols-2 lg:grid-cols-3 gap-6 xl:gap-8">
          <Report v-for="report in filteredReports" :key="report.id" :report="report"></Report>
        </div>
        <!--todo-->
        <!--<a href="#" class="bg-agra-green py-4 px-6 inline-block mt-8 text-white">Load More <i
                class="fas fa-sync text-sm ml-1"></i> </a>-->
      </div>
    </section>
  </div>
</template>

<script>
import Report from './shared/Report';
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      unsubscribe: null,
      filters: {
        term: '',
        region: null,
        tag: null
      },
      tags: [],
      regions: [],
      featuredReport: null,
      firstTimeLoaded: false,
    }
  },
  computed: {
    ...mapState(['reports']),
    filteredReports() {
      let _this = this;
      let reports = this.reports;

      if (this.filters.tag) {
        reports = this.reports.filter(report => {
            return report.tags.includes(_this.filters.tag);
        });
      }

      if (this.filters.region) {
        reports = reports.filter(report => {
          return report.country === _this.filters.region;
        });
      }

      if (this.filters.term) {
        reports = reports.filter(report => {
          return report.titleLong.toLowerCase().includes(_this.filters.term.toLowerCase()) ||
                  report.title.toLowerCase().includes(_this.filters.term.toLowerCase());
        });
      }

      return reports;
    },
    tagParam() {
      return this.$route.query.tag || null;
    },
    regionParam() {
      return this.$route.query.region || null;
    },
    termParam() {
      return this.$route.query.term || null;
    }
  },
  components: {
    Report
  },
  watch: {
    tagParam() {
      this.setTagParams();
      /*const section = this.$refs['grid-reports'];
      section.scrollIntoView({behavior: 'smooth', block: 'start'});*/
    },
    regionParam() {
      this.setRegionParams();
      /*const section = this.$refs['grid-reports'];
      section.scrollIntoView({behavior: 'smooth', block: 'start'});*/
    },
    term() {
      this.setTermParams();
      /*const section = this.$refs['grid-reports'];
      section.scrollIntoView({behavior: 'smooth', block: 'start'});*/
    },
    reports: {
      handler: 'getAllFilters'
    },
    filters: {
      deep: true,
      handler: 'onDataChange'
    }
  },
  methods: {
    ...mapActions(['fetchReports']),
    setTagParams() {
      const tagParams = this.$route.query.tag;

      if (!tagParams) return;
      this.filters.tag = tagParams;
    },
    setRegionParams() {
      const regionParams = this.$route.query.region;

      if (!regionParams) return;
      this.filters.region = regionParams;
    },
    setTermParams() {
      const termParams = this.$route.query.term;

      if (!termParams) return;
      this.filters.term = termParams;
    },
    setReportParams() {
      const reportParams = this.$route.query.report;

      if (!reportParams) return;
      this.featuredReport = this.reports.find(report => report.id === reportParams);
    },
    getAllFilters() {
      if (this.reports.length && !this.firstTimeLoaded) {
        let tags = [];
        let regions = [];

        for(let i = 0; i < this.reports.length; i ++) {
          tags = tags.concat(this.reports[i].tags);
          regions = regions.concat(this.reports[i].country);
        }

        this.tags = tags.filter((v, i, a) => a.indexOf(v) === i);
        this.regions = regions.filter((v, i, a) => a.indexOf(v) === i);

        this.firstTimeLoaded = true;
      }
    },
    onDataChange() {
      let params = {};

      if (this.filters.tag) {
        params.tag = this.filters.tag;
      }

      if (this.filters.region) {
        params.region = this.filters.region;
      }

      if (this.filters.term) {
        params.term = this.filters.term;
      }

      this.$router.push({query: params});
    }
  },
  async mounted() {
    await this.fetchReports();
    setTimeout(() => {
      this.getAllFilters();
    }, 1000);
    this.setTagParams();
    this.setRegionParams();
    this.setTermParams();
    this.setReportParams();
  }
}
</script>
